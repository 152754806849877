<template>
    <div class="about-area" style="margin-bottom: 110px;">
        <div class="container2 ml-5 mr-5  ml-lg-90 mr-lg-90">
            <div class="row">
                <div class="col-md-12 mt-5">
                    <h2 class="hr-lines text-center mt-5">Prominent Clients</h2>
                    <h2 class="hr-line d-none d-md-flex text-center mt-2"></h2>
                </div>
                <div class="container">
                    <div class="row flex-container">
                        <template v-for="n in 10">
                            <div class="col-6 col-md-3 col-lg-2" :key="n"><img :src="img(n)" alt=""></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "prominent-client",
        methods:{
            img(n){
                return '/img/client/' + parseInt(n+1) + '.jpg'
            },
            imgSecond(n){
                return '/img/client/' + parseInt(n+6) + '.jpg'
            },
            partnerImage(n){
                return '/img/partner/' + parseInt(n) + '.jpg'
            },
            partnerImage2(n){
                return '/img/partner/' + parseInt(n+5) + '.jpg'
            }
        },//end of methods
    }
</script>

<style scoped>
    .default-style{
        background-color: #e54c2a;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    line-height: 0;
    padding: 16px 30px;
    text-transform: uppercase;
    }
    /* .flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    } */
    .hr-lines {
        font-family: 'CastorTwoW01-Regular';
font-style: normal;
font-weight: 400;
font-size: 38px;
line-height: 46px;
/* identical to box height */

text-align: center;
letter-spacing: 0.08em;

/* Black */

color: #231F20;
    }

.hr-line1:before{
      content:" ";
      height: 3px;
      margin-left: -90px;
      width: 37.5%;
      background: #C0272D;
      position: absolute;
      top: 60%;
      left: 0;
    }
.hr-lines2 {
    font-family: 'CastorTwoW01-Regular';
font-style: normal;
font-weight: 400;
font-size: 38px;
line-height: 46px;
/* identical to box height */

text-align: center;
letter-spacing: 0.08em;

/* Black */

color: #231F20;
    }

.hr-line:after{
      content:" ";
      margin-right: -35px;
      margin-top: 30px;
      height: 3px;
      width: 38%;
      background: #C0272D;
      display: block;
      position: absolute;
      top: 38%;
      right: 0;
    }
    @media screen and (max-width: 767px) {
        .hr-lines{
            font-size: 30px;
        }
        .flex-container{
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    justify-content: space-between;
    width: 100%;
        }
        ::-webkit-scrollbar {
        color: #e54c2a;
        scrollbar-width: none;
        height: 2px;  
}
   
    
}
@media screen and (max-width: 1100px) {
    .hr-line:after{
        width: 35%;
    }

}
</style>
