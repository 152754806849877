<template>
    <div>
      <NewSlider></NewSlider>
      <!-- <HomepageCategoty /> -->
      <!-- <NewWelcome></NewWelcome> -->
      <NewFeatureProduct></NewFeatureProduct>
      <NewRetail></NewRetail>
      <DownloadApp></DownloadApp>
      <ProminentClients />
      
    </div>
  </template>
  
  <script>
   
  import NewSlider from "../../components/home/SliderComponent.vue"
  // import NewWelcome from "../../components/new_home/Welcome.vue"
  import NewRetail from '../../components/home/Retailer.vue';
  import NewFeatureProduct from '../../components/home/FeatureProduct.vue';
    import DownloadApp from '../../components/home/DownloadApp.vue'
    import ProminentClients from '../../components/home/ProminentClients.vue';
  //  import NewCardSlide from "../../components/new_home/CardSlide.vue";
  //  import NewHeroProduct from "../../components/new_home/NewHeroProduct.vue";
  //  import PopularProduct from "../../components/new_home/PopularProduct.vue";
// import HomepageCategoty from "../../components/new_home/HomepageCategoty.vue";
  
    export default {
        components: {
         NewSlider,
        // NewWelcome,
        DownloadApp,
        ProminentClients,
        NewRetail,
        NewFeatureProduct,
        // PopularProduct,
        // NewHeroProduct,
        // HomepageCategoty
        },

        metaInfo: {
          title: 'German Butcher',
          titleTemplate: '%s - GB',
          htmlAttrs: {
            lang: 'en',
            amp: true
          },
          meta:[
            {
                name: 'description',
                content: 'In 1991, Ferenz Georgy started German Butcher in Bangladesh with a love for sausages noticing the unavailability of such products in our country. Since then, German Butcher is the pioneer of authentic German Sausages in Bangladesh and became the icon of premium quality gourmet sausages, cold cuts, ham, bacon, meatloaf, salami, pepperoni and so many meat based products',
                vmid: 'vabout'
            }
          ]
        },


    }
  </script>