var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.testimonial.length < 1)?_c('div',[_c('v-sheet',{staticClass:"mx-auto",staticStyle:{"margin-bottom":"10px"},attrs:{"max-width":"99.5%"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"active-class":"success","max":"3","min":"1","show-arrows":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"left-btn",attrs:{"plain":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('img',{staticStyle:{"height":"48px","width":"48px","padding-right":"20px"},attrs:{"src":"/img/left-arrow.svg"}})])]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"right-btn",attrs:{"plain":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('img',{staticStyle:{"height":"48px","width":"48px","padding-left":"20px"},attrs:{"src":"/img/right-arrow.svg"}})])]}}],null,false,2101784900),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((6),function(n,i){return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [[_c('div',{staticStyle:{"margin-bottom":"25px"}},[_c('div',{staticClass:"card card-design"},[_c('div',{staticClass:"gradient-inside"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticStyle:{"margin-top":"10px","margin-bottom":"10px","height":"80px"}},[_c('img',{staticClass:"card-img-top content",attrs:{"src":"/img/logo.png","alt":"Card image cap"}})])]),_c('div',{staticStyle:{"text-align":"center","font-family":"'Quicksand', sans-serif"}},[_c('h5',{staticClass:"card-title-own sp uppercase",attrs:{"title":"Lorem Ipsum"}},[_vm._v(" John doe ")]),_c('p',{staticClass:"card-text card-text-own",staticStyle:{"letter-spacing":"1px"}},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley ")])])])])])]]}}],null,true)})}),1)],1)],1):_c('div',[_c('v-sheet',{staticClass:"mx-auto my-component",staticStyle:{"margin-bottom":"10px"},attrs:{"max-width":"99.5%"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"active-class":"success","max":"3","min":"1","show-arrows":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"left-btn",attrs:{"plain":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('img',{staticStyle:{"height":"48px","width":"48px","padding-right":"20px"},attrs:{"src":"/img/left-arrow.svg"}})])]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"right-btn",attrs:{"plain":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('img',{staticStyle:{"height":"48px","width":"48px","padding-left":"20px"},attrs:{"src":"/img/right-arrow.svg"}})])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.testimonial),function(tm,i){return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('ProminentClientsCard',{attrs:{"name":tm.name,"description":tm.description,"img":tm.thumbnail}})]}}],null,true)})}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }