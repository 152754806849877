<template>
  <div style="margin-bottom:25px">
    <div class="card card-design">
        <div class="gradient-inside"> 
          <div  class="d-flex justify-content-center">
            <div style="margin-top: 10px; margin-bottom: 10px; height: 80px;">
              <img v-if="hasImage" class="content" :src="img" alt="Client photo ">
              <img v-else class="card-img-top content" src='/img/pp.png' alt="Card image cap"/>
            </div>
          </div>

        <div class="" style="text-align: center;  font-family: 'Quicksand', sans-serif;">
            <h5 class="card-title-own sp uppercase mt-1" title="Lorem Ipsum">{{ name }}</h5>
          <p class="card-text card-text-own " style="letter-spacing: 1px;">{{ description }}</p>
        </div>

      </div>

    
    </div>
</div>
    </template>
  
  <script>
    export default {
      props: {
      name: String,
      description: String,
      img:String,

    },  
    computed:{
      hasImage() {
      return !!this.img;
    },}

    }
   
  </script>
  
  <style scoped>
  .content {
    border-radius: 50%;
    margin-top: 5px;
    height: 80px;
    width: 80px;
    padding: 3px;
    border: 2px solid #C0272D;
    object-fit: contain;
  }
  .gradient-inside {
    background-color: white;
    border-radius: 18%;
    height: 265px;
    padding-bottom: 20px;
    font-family: 'Quicksand', sans-serif;
  }
  .card-design {
    width: 324px;
    height: 280px;
    border-radius: 10%;
    padding-top:8px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 8px;
    margin: 12.5px;
    margin-top: 50px;
    background: linear-gradient(90deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #A8752A 100%);
    font-family: quicksand;
    border: none;
    border-radius: 18%;
  }

  .card-text-own {
    max-height: 100%;
    font-family: Quicksand;
    color:#000;
    white-space: pre-wrap;
    word-break: break-word;
    font-weight: 550;
    overflow: scroll;
    font-size: 12px;
    line-height: 17.5px;
    padding-left: 19px;
    padding-right: 19px;
    margin-top: 15px;
    
  }
  
  
  
  @media (max-width: 1200px){
     .card-design {
            margin-right: 50px;
        }
        

    }
  @media (max-width:1112px){
     .card-design {
            margin-right: 50px;
        }

    }
    @media (max-width:913px){
     .card-design {
            width: 265px;
            margin-right: 16px;
        }

    }
    @media (max-width:834px){
     .card-design {
            width: 315px;
            margin-right: 25px;
        }

    }
    @media (max-width:820px){
     .card-design {
            width: 265px;
            margin-left: 15px;
        }

    }
    @media (width:800px){
     .card-design {
            width: 275px;
            margin-left: 35px;
        }

    }
    @media (width:820px){
     .card-design {
            width: 265px;
            margin-left: 50px;
        }

    }
    @media (max-width:782px){
     .card-design {
            width: 280px;
            margin-right: 35px;
        }
        .card-text-own {
    padding-left: 10px;
    padding-right: 10px;
    
  }

    }
    @media (max-width:768px){
     .card-design {
            width: 281px;
            margin-right: 25px;
        }

    }
    @media (width:768px){
     .card-design {
            width: 281px;
            margin-right: 22px;
        }

    }
    
    @media (max-width:736px){
     .card-design {
            width: 185px;
            margin-right: 6px;
        }

    }
    @media (width:736px){
     .card-design {
            width: 185px;
            margin-right:1px;
        }

    }
    @media (width:720px){
     .card-design {
            width: 275px;
            margin-right:5px;
        }

    }
    @media (max-width:694px){
     .card-design {
            width: 245px;
            margin-right: 25px;
        }

    }
    @media (max-width:678px){
     .card-design {
            width: 245px;
            margin-right: 15px;
        }

    }
    @media (max-width:667px){
     .card-design {
            width: 245px;
            margin-right: 10px;
        }

    }
    @media (max-width:639px){
     .card-design {
            width: 235px;
            margin-right: 6px;
        }

    }
    @media (width:600px){
     .card-design {
            width: 217px;
            margin-right: 0px;
            margin-left: 16px;

        }

    }
   
    @media (max-width:568px){
     .card-design {
            width: 215px;
            margin-left: 0;
            margin-right: 4px;
        }

    }
    @media (max-width:551px){
     .card-design {
            width: 205px;
            margin-left: 0;
            margin-right: 5px;
        }

    }
    @media (max-width:540px){
     .card-design {
            width: 201px;
            margin-left: 0;
            margin-right: 4px;
        }

    }
    @media (max-width:510px){
     .card-design {
            width: 182px;
            margin-left: 0;
            margin-right: 7px;
        }

    }
  @media (max-width:450px){
     .card-design {
            width: 260px;
            margin-left: 0;
            margin-right: 24px;
        }

    }
    @media (max-width:438px){
     .card-design {
            width: 260px;
            margin-left: 0;
            margin-right: 50px;
        }

    }
    @media (width:414px){
     .card-design {
            width: 260px;
            margin-left: 12px;
            margin-right: 12px;
        }

    }
    @media (width:412px){
     .card-design {
            width: 260px;
            margin-left: 11px;
            margin-right: 11px;
        }

    }
    
    @media (max-width:393px){
     .card-design {
            width: 240px;
            margin-right: 10px;
            margin-left: 13px;
        }
        .card-text-own{
          margin-top: 0px !important;
          font-size: 11px;
        }
    }
    @media (max-width:390px){
     .card-design {
            width: 235px;
            margin-right: 10px;
            margin-left: 14px;
        }

    }
    @media (max-width:375px){
     .card-design {
            width: 235px;
            margin-left: 0px;
        }
        .card-text-own {
    padding-left: 5px;
    padding-right: 5px;
    
  }

    }
    @media (max-width:360px){
     .card-design {
            width: 221px;
            margin-left: 0px;
        }

    }
    @media (max-width:320px){
     .card-design {
            width: 180px;
            margin-right: 0;
            margin-left: 10px;
        }

    }
    
  .card-title-own {
    font-family: Quicksand;
    color: #000;
    font-size:16px;
    overflow:hidden;
    font-weight: 550;
    text-overflow: ellipsis;  
    letter-spacing: 1px;
    
  }
/* Add this if there is an overflow when fetching form database and want to show when hover */

  /* .card-title-own:hover {
    white-space: pre-wrap;
    word-wrap: break-word;
  } */
 


  ::-webkit-scrollbar {
    height: 0;
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  </style>
