<template>
  <div class="container">
    <carousel :items="1" :nav="false" :autoplay="true" :key="promobanner.length" >
      <div v-for="(img, index) in promobanner" :key="'carouselpromo-'+index">
        
        <a :href="`/products/discount-product/${img.id}/products`">
          <img :src="img.thumbnail" class="image-container" />
        </a>
      </div>
    </carousel>
  </div>
</template>
  
    
    <script>
import Carousel from 'vue-owl-carousel';
export default {
  components: {
    Carousel,
  },
  data() {
    return {
      promobanner: [],
    };
  },

  methods: {
    fetchPromoBanner() {
      this.axios
        .get("/promo-banner")
        .then((response) => {
          this.status = response.data.status;
          this.promobanner = response.data.promobanner;
        })
        .catch((error) => {
          throw error;
        });
    },
    // this.notify()
  },
  mounted() {
    this.fetchPromoBanner();
  },
};
</script>
  
  
  <style scoped>
/*@font-face {
      font-family: myfont;
      src: url('/fonts/Ubuntu-R.ttf');
  }*/

.image-container {
  margin-inline: auto;
  max-width: 100%;
  height: 220px;
  margin-top: 15px;
  box-shadow: 10px 10px 5px #ccc;
     
}
.VueCarousel-slide {
visibility: visible;
flex-basis: 100%;
width: 100%;
}
</style>
    
