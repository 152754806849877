<template>
 
        <div style="margin-top:60px;">
        <!-- <h1>Prominent Clients</h1> -->
        <div class="d-none d-lg-flex justify-content-center" style="position: relative;">
            <div class="prominent">
               Customer Testimonial
            </div>
        </div>
        <div class="container">
            <ProminentClientsSlide/>            
        </div>
    </div>


</template>
<script>
  import ProminentClientsSlide from './ProminentClientsSlide.vue';
  export default{
    components: {ProminentClientsSlide},
    name:"ProminentClients",

  }
</script>

<style scoped>
@font-face {
    font-family: 'Quicksand';
    src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
/* .background_prominent > h1 {
    font-family: Castor Two W01 Regular;
    color: black;
    font-weight: 400;
    font-size: 4.75vh;
    position: relative;
    text-align: center;
}

.background_prominent > h1:before {
    content:" ";
    height: 0.3vh;
    width: 40%;
    background: rgb(166, 0, 0);
    display: block;
    position: absolute;
    top: 55%;
    right: 61%;
        
} */
@font-face {
    font-family: 'Castor Two W01 Regular';
    src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
}
.prominent {
    font-family: 'Castor Two W01 Regular';
    color: black;
    font-weight: 400;
    font-size: 2.375rem;
}
.prominent:before {
  content: '';
  position: absolute;
  top: 55%;
  left: 0;
  border-top: 3px solid  rgb(166, 0, 0);
  width: 32%;
}

 

@media (max-width: 900px){
  .container {
    max-width: 900px;
 }
 .container {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
}
}
@media (min-width: 1280px){
    .container {
    max-width: 1185px;
    }
  }
  @media (width: 1200px){
  .container {
    max-width: 900px;
 }
}
  
  @media (width: 1024px){
  .container {
    max-width: 900px;
 }
}
 @media (width: 1080px){
  .container {
    max-width: 900px;
 }
 
}
@media (width: 981px){
  .container {
    max-width: 900px;
 }
}

 @media (width: 1112px){
  .container {
    max-width: 900px;
 }
}
 

@media (max-width:1000px){
     .prominent:before {
            width: 30%;
        }

    }
@media (max-width:690px){
     .prominent:before {
            width: 25%;
        }

    }
    @media (max-width:550px){
     .prominent:before {
            width: 22%;
        }

    }

@media (max-width:530px){
     .prominent:before {
            border: none;
     }

    }

</style>
