<template>
    <div style="background: linear-gradient(312deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%);margin-top: 0px; z-index:0; position: relative;">
        <div>
            <PromoBanner />
        </div>
        <div v-if="loading" class="col-md-12" style="position: relative;" >
                    <h2 class="hr-lines2 text-center " style="margin-top: 100px;">Featured Products</h2>
                    
        </div>
        <div v-if="loading" class="slider-area container text-sm-center" id="product-slider" >
            <div class="row m-0">
               
                <div  v-for="n in 20" :key=n class="b-center text-center col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 g-4">
                
                    <div class="img-div mt-4 mr-4 text-sm-center">
                        <skeleton-box
                           width="240px"
                           height="240px"
                           style="border-radius:10%;"   
                        />
                    </div>
            
                </div>
            </div>
            <div v-if="!loading" class="container">
                <component :videos="videos" v-bind:is="currentTabComponent"></component>
            </div>
        </div>
    
        <div v-else class="bg-product">
            <div class="col-md-12" style="position: relative; padding-top: 10px;">
                    <h2 v-if="products.length>1" class="hr-lines2 text-center " style="font-weight: 500;">Featured Products</h2>
                    <hr style="" class="d-none d-lg-flex productline2" />
                </div>
                <div class="container col-md-12 productList-W">
                    <!-- <div class="row single-blog"> -->
                    <ProductList :products="products" />
                    <!-- </div> -->
                </div>
                <div class="col-md-12" style="position: relative;padding-top: 40px;">
                    <h2 v-if="products.length>1" class="hr-lines2 text-center mt-2 ">Popular Products</h2>
                    <h2 class="hr-line12 d-none d-md-flex text-center mt-4"></h2> 
                </div>
                <div class="container col-md-12 productList-W" style="">
                    <!-- <div class="row single-blog"> -->
                    <ProductList :products="popular_products" />
                    
                    <!-- </div> -->
                </div>
        </div>
        
        <!-- snackbar -->
        <v-snackbar v-model="snackbar">
            {{ text }}
            <v-btn color="pink" text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>
        <!-- snackbar -->
    </div>
    </template>
    
    <script>
    import Slider from '@/components/home/Slider'
    import ProductList from '@/components/NewProductList'
    import ProductListMobile from '@/components/ProductList.vue'
    import SkeletonBox from '../common_components/SkeletonBox.vue'
    import PromoBanner from './PromoBanner.vue'

    export default {
        name: 'FeaturedProduct',
        components: {
            Slider,
            ProductList,
            SkeletonBox,
            ProductListMobile,
            PromoBanner
        },
        data() {
            return {
                loading: true,
                image: './img/banner/count.jpg',
                snackbar: false,
                text: '',
                searchKey: '',
                tempProducts: [],
                state: 0,
                products: [],
                popular_products: [],
                videos: [],
                articles: [],
                img: 'https://s3.ap-south-1.amazonaws.com/german-butcher/'
            }
        }, // end of data
        computed: {
            currentTabComponent: function () {
                if (this.videos.length > 0) {
                    return 'Slider'
                }
                return '';
            },
            showVideoSection: function () {
                if (this.videos.length > 0) {
                    return true
                } else {
                    return false
                }
            }
        },
        methods: {
            replaceSpace(name) {
                return name.replace(/[\W_]/g, "-")
            },
            
            cartAdded(product) {
                this.productId = product.product_id;
                this.$store.commit('addTCart', product);
                this.snackbar = true,
                    this.text = "Added to cart"
                localStorage.setItem('cart', JSON.stringify(this.$store.getters.cart_Info));
                // this.notify()
            },
    
            search() {
                if (this.state == 0) {
                    this.tempProducts = this.products
                    this.state = 1
                }
                var filteredItems = this.tempProducts.filter(item => {
                    if (item.name.toLowerCase().match(this.searchKey.toLowerCase())) {
                        return item;
                    }
                })
                this.products = filteredItems
            },
            showAllproduct() {
                if (this.state == 1) {
                    this.products = this.tempProducts
                    //this.state = 1
                }
            },
            filterBySub(categories_id) {
                if (this.state == 0) {
                    this.tempProducts = this.products
                    this.state = 1
                }
                var filteredItems = this.tempProducts.filter(item => {
                    if (item.fk_categories_id == categories_id) {
                        return item;
                    }
                })
                this.products = filteredItems
            },
            notify() {
                this.$notify({
                    group: 'cart',
                    type: 'error',
                    title: 'Added to Cart',
                    //text: 'Hello user! This is a notification!'
                });
            }
        },
        async mounted() {
            function featuredSort(a, b) {
                if (a.feature_priority == null) {
                    a.feature_priority = 99999
                }
                if (b.feature_priority == null) {
                    b.feature_priority = 99999
                }
                if (a.feature_priority < b.feature_priority) {
                    return -1;
                }
                if (a.feature_priority > b.feature_priority) {
                    return 1;
                }
    
                return 0;
            }
            const [{data: {data: featuredProducts}}, {data: {data: popularProducts}}] = await Promise.all([
                this.axios.get(`/products/all?is_featured=1`),
                this.axios.get(`/products/all?is_popular=1`)
            ]);
            this.loading = false

            this.products = featuredProducts.map(item => {
                    if(item.feature_priority != null){
                        item.feature_priority = parseInt(item.feature_priority)
                        return item;
                    }
                    return item;
                })
                .sort(featuredSort)

            this.popular_products = popularProducts  
        }
    }
    </script>
    
    <style scoped>
    /*@font-face {
        font-family: myfont;
        src: url('/fonts/UbuntuCondensed.ttf');
    }*/
    hr{
        height:3px;
        background-color: #d52b05;
        border: none;
    }
    .text-white {
        color: white;
    }
    .hr-line12::before{
      content:" ";
      height: 3px;
      margin-left: -90px;
      width: 40%;
      background: #C0272D;
      position: absolute;
      top: 50%;
      left: 0;
    }
    .hr-line2::after{
        content:" ";
      height: 3px;
      width: 36%;
      background: #C0272D;
      display: block;
      position: absolute;
      top: 78%;
      right: 0;
    }
    .hr-lines2 {
  margin-top: 0px;
font-family: 'CastorTwoW01-Regular';
font-style: normal;
font-weight: 400;
font-size: 38px;
line-height: 46px;
/* identical to box height */

text-align: center;
letter-spacing: 0.08em;

/* Black */

color: #231F20;
    }
.productline2{
  margin-top: -30px; 
  margin-left: calc(100% - 450px);
  color: #C0272D;
}

    @media (max-width:1100px){
        .productline2{
  margin-top: -30px; 
  margin-left: calc(100% - 350px);
  color: #C0272D;
}
     .hr-line2:after {
            width: 28%;
        }

    }
    .bg-product{
        
        padding-bottom: 60px;
    margin-bottom: -60px;
    margin-top: 45px;
    }
    
    .product-name {
        line-height: 20px;
        height: 30px;
    }
    
    .overlay {
        display: none;
        position: absolute;
        background-color: rgba(40, 40, 40, .75);
        height: 52%;
        width: 90%;
        top: 0px;
    }
    
    .product:hover .overlay {
        display: inline;
    }
    
    .overlay-text {
        font-size: 20px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        color: wheat;
        transform: translate(-50%, -50%);
    }
    .productList-W{
        display: block; 
        position:relative; 
        align-items:center; 
        margin-top: -50px;
    }
 


    @media (max-width:434px){
        .b-center{
            margin-left:80px ;
        }
    }
    @media (max-width:1025px){
        .productline2{
  margin-top: -30px; 
  margin-left: calc(100% - 300px);
  color: #C0272D;
}
    }
    @media screen and (max-width: 767px){
        .hr-lines2{
            font-size: 30px;
        }
        
    }
    

    </style>
    