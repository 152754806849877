<template>
  <div class="feature">
    <carousel
      :products="products"
      ref="carousel"
      :responsive="{ 0: { items: 1, nav: false, dots: true } }"
      :autoplay="true"
    >
      <div v-for="item in products" :key="item.products_id">
        <div class="item">
          <div class="col-12 p-1 mt-3">
            <router-link
              class="readmore"
              :to="{
                name: 'newestore.newproduct.show',
                params: { id: item.products_id },
                query: { name: replaceSpace(item.name) },
              }"
            >
              <div class="card-design m-auto">
                <div class="gradient-inside">
                  <img
                    v-if="item.images.length > 0"
                    style=""
                    alt=""
                    :src="item.images[0].path"
                    class="text-center"
                  />
                  <div v-if="item.available == 0" class="overlay">
                    <div class="overlay-text">Out of Stock</div>
                  </div>
                </div>
              </div>
            </router-link>

            <p class="text-center product-name mx-auto mt-4">
              <router-link
                class="readmore"
                :to="{
                  name: 'newestore.newproduct.show',
                  params: { id: item.products_id },
                  query: { name: replaceSpace(item.name) },
                }"
              >
                {{ item.name }}
              </router-link>
            </p>
            <hr
              class="ml-4 mr-3"
              style="
                margin-top: -15px;
                margin-bottom: 14px;
                height: 1px;
                background-color: #000;
                border: none;
              "
            />

            <div class="text mx-auto pl-1" style="width: max-content">
              <div style="display: flex">
                <div class="text-center" style="width: 70px; font-weight: bold">
                  <div class="d-flex">
                    <img
                      src="/img/product/0001.png"
                      style="height: 13px; width: 10px; margin-top: 4px"
                    />
                    <div style="margin-left: 5px">{{ item.weight }}</div>

                    <div v-if="item.unit !== null">{{ item.unit.name }}</div>
                  </div>
                </div>
                <!--<span v-if="item.variable_weight == 1">(final cost based on weight)</span>-->
                <div class="ml- mr-2">
                  <span v-if="item.promo_banners.length <= 0" style="">
                    <span class="text" style="color: #c0272d">৳</span
                    >{{ item.price }}</span
                  >
                  <template v-if="item.promo_banners.length > 0">
                    <span>
                      <del
                        ><span class="text" style="color: #c0272d">৳</span
                        >{{ item.price }}</del
                      ></span
                    >
                    <span
                      v-if="item.promo_banners[0].is_discount_percentage == 0"
                      style="
                        font-size: 13px;
                        font-weight: bold;
                        padding-left: 8px;
                        padding-right: 10px;
                        display: block;
                      "
                    >
                      <strong class="text-danger">৳ </strong>
                      {{
                        (item.price - item.promo_banners[0].discount).toFixed(2)
                      }}</span
                    >
                    <span
                      v-if="item.promo_banners[0].is_discount_percentage == 1"
                      style="
                        font-size: 13px;
                        font-weight: bold;
                        padding-left: 8px;
                        padding-right: 10px;
                        display: block;
                      "
                    >
                      <strong class="text-danger">৳ </strong>
                      {{
                        (
                          item.price -
                          (item.price * item.promo_banners[0].discount) / 100
                        ).toFixed(2)
                      }}</span
                    >
                  </template>
                </div>
                <div style="margin-top: -4px">
                  <button
                    :disabled="item.available == 0"
                    @click="cartAdded(item)"
                    class="btn text-white ml-5 px-lg-3 text-center"
                    style="display: flex"
                  >
                    <img
                      src="/img/product/cart-icon-2.png"
                      style="height: px; width: 20px; font-weight: bolder"
                      class="mt-1"
                    /><span class="ml-lg-1 mt-1">Add to Cart</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center mx-auto">
              <template v-if="item.discount != 0">
                <!-- <span  style="font-size:16px; padding-left:8px; padding-right: 10px;"> <strong class="text-danger">৳ </strong> <s>{{ item.price }}</s></span> -->
                <span class="text discount"
                  ><span style="color: #c0272d">৳</span
                  >{{ (item.price - item.discount).toFixed(2) }}</span
                >
                <!-- <span  class="discount" style="font-size:15px;"> <strong class="text-danger">৳ </strong> {{ (item.price - item.discount).toFixed(2) }}</span> -->
              </template>
            </div>
          </div>
        </div>
      </div>
    </carousel>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: {
    carousel,
  },
  data() {
    return {
      loading: true,
      snackbar: false,
      text: "",
      img: "/img/product/image.png",
      activeSlideIndex: 0,
    };
  },
  props: {
    products: { required: true },
    backgroundColor: {
      type: String,
      required: true,
    },
  },

  watch: {
    products: function () {
      this.loading = false;
    },
  },
  computed: {
    activeSlideClass() {
      return `slide-active-${this.activeSlideIndex}`;
    },
  },
  methods: {
    replaceSpace(name) {
      return name.replace(/[\W_]/g, "-");
    },
    cartAdded(product) {
      const newProduct = { ...product };
      if (newProduct.promo_banners[0] && newProduct.promo_banners[0].discount) {
        newProduct["discount"] = newProduct.promo_banners[0].discount;
        newProduct["is_discount_percentage"] =
          newProduct.promo_banners[0].is_discount_percentage;
      } else {
        newProduct["discount"] = 0;
        newProduct["is_discount_percentage"] = 0;
      }

      this.productId = newProduct.product_id;
      this.$store.commit("addTCart", newProduct);
      (this.snackbar = true), (this.text = "Added to cart");
      localStorage.setItem(
        "cart",
        JSON.stringify(this.$store.getters.cart_Info)
      );
      // this.notify()
    },
    onSlideChange(val) {
      this.activeSlideIndex = val;
    },
  },
  onClick() {
    // Get the current carousel
    const carousel = this.$refs.carousel.current;
    // If the current carousel is not the last carousel,
    // then go to the next carousel
    if (carousel.index < this.products.length - 1) {
      carousel.next();
    }
  },
  //end of methods
};
</script>

<style scoped>
.my-custom-prev-button {
  /* Styles for the previous button */
  width: 100%;
  text-align: center;
}

.my-custom-next-button {
  /* Styles for the next button */
  width: 100%;
  text-align: center;
}

.left-btn >>> .v-slide-group__prev .v-slide-group__prev--disabled {
  min-width: 35px !important;
}

.v-slide-group__prev {
  min-width: 35px !important;
}

.left-btn {
  padding-left: 0px;
}
.right-btn {
  padding-right: 0px;
  min-width: none !important;
}
.content {
  border-radius: 50%;
  margin-top: 5px;
  max-height: 70px;
  width: 70px;
  border: 2px solid #c0272d;
}
.gradient-inside {
  background-color: white;
  border-radius: 18%;
  height: 265px;
  padding-bottom: 20px;
  font-family: "Quicksand", sans-serif;
}
.card-des {
  width: 324px;
  height: 280px;
  border-radius: 10%;
  padding-top: 8px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 8px;
  margin: 12.5px;
  margin-top: 50px;

  font-family: quicksand;
  border: none;
  border-radius: 18%;
}

.card-text-own {
  max-height: 100%;
  font-family: Quicksand;
  color: #000;
  white-space: pre-wrap;
  word-break: break-word;
  font-weight: 550;
  overflow: scroll;
  font-size: 12px;
  line-height: 17.5px;
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 15px;
}

@media (max-width: 1200px) {
  .card-des {
    margin-right: 50px;
  }
}
@media (max-width: 1112px) {
  .card-des {
    margin-right: 50px;
  }
}
@media (max-width: 913px) {
  .card-des {
    width: 265px;
    margin-right: 16px;
  }
}
@media (max-width: 834px) {
  .card-des {
    width: 315px;
    margin-right: 25px;
  }
}
@media (max-width: 820px) {
  .card-des {
    width: 265px;
    margin-left: 15px;
  }
}
@media (width: 800px) {
  .card-des {
    width: 275px;
    margin-left: 35px;
  }
}
@media (width: 820px) {
  .card-des {
    width: 265px;
    margin-left: 50px;
  }
}
@media (max-width: 782px) {
  .card-des {
    width: 280px;
    margin-right: 35px;
  }
  .card-text-own {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .card-des {
    width: 281px;
    margin-right: 25px;
  }
}
@media (width: 768px) {
  .card-des {
    width: 281px;
    margin-right: 22px;
  }
}

@media (max-width: 736px) {
  .card-des {
    width: 185px;
    margin-right: 6px;
  }
}
@media (width: 736px) {
  .card-des {
    width: 185px;
    margin-right: 1px;
  }
}
@media (width: 720px) {
  .card-des {
    width: 275px;
    margin-right: 5px;
  }
}
@media (max-width: 694px) {
  .card-des {
    width: 245px;
    margin-right: 25px;
  }
}
@media (max-width: 678px) {
  .card-des {
    width: 245px;
    margin-right: 15px;
  }
}
@media (max-width: 667px) {
  .card-des {
    width: 245px;
    margin-right: 10px;
  }
}
@media (max-width: 639px) {
  .card-des {
    width: 235px;
    margin-right: 6px;
  }
}
@media (width: 600px) {
  .card-des {
    width: 217px;
    margin-right: 0px;
    margin-left: 16px;
  }
}

@media (max-width: 568px) {
  .card-des {
    width: 215px;
    margin-left: 0;
    margin-right: 4px;
  }
}
@media (max-width: 551px) {
  .card-des {
    width: 205px;
    margin-left: 0;
    margin-right: 5px;
  }
}
@media (max-width: 540px) {
  .card-des {
    width: 201px;
    margin-left: 0;
    margin-right: 4px;
  }
}
@media (max-width: 510px) {
  .card-des {
    width: 182px;
    margin-left: 0;
    margin-right: 7px;
  }
}
@media (max-width: 450px) {
  .card-des {
    width: 260px;
    margin-left: 0;
    margin-right: 24px;
  }
}
@media (max-width: 438px) {
  .card-des {
    width: 260px;
    margin-left: 0;
    margin-right: 50px;
  }
}
@media (width: 414px) {
  .card-des {
    width: 260px;
    margin-left: 12px;
    margin-right: 12px;
  }
}
@media (width: 412px) {
  .card-des {
    width: 260px;
    margin-left: 11px;
    margin-right: 11px;
  }
}

@media (max-width: 393px) {
  .card-des {
    width: 240px;
    margin-right: 10px;
    margin-left: 13px;
  }
}
@media (max-width: 390px) {
  .card-des {
    width: 235px;
    margin-right: 10px;
    margin-left: 14px;
  }
}
@media screen and (width: 375px) {
  /* .card-design {
  width: 280px !important;
  height: 220px !important;
} */

  .card-text-own {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 360px) {
  .card-des {
    width: 221px;
    margin-left: 0px;
  }
}
@media (max-width: 320px) {
  .card-des {
    width: 180px;
    margin-right: 0;
    margin-left: 10px;
  }
}

.card-title-own {
  font-family: Quicksand;
  color: #000;
  font-size: 16px;
  overflow: hidden;
  font-weight: 550;
  text-overflow: ellipsis;
  letter-spacing: 1px;
}
/* Add this if there is an overflow when fetching form database and want to show when hover */

/* .card-title-own:hover {
    white-space: pre-wrap;
    word-wrap: break-word;
  } */

::-webkit-scrollbar {
  height: 0;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
@font-face {
  font-family: "Quicksand", sans-serif;
  src: url("/fonts/Quicksand-VariableFont_wght.ttf");
}

.readmore {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: normal;
  /* identical to box height, or 125% */

  text-align: center;
  line-height: 20px;

  /* Black */

  color: #231f20;
}

@media screen and (max-width: 767px) {
  .mt- {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .col-12 {
    max-width: 100%;
  }
  /* Change the color and size of the scroll sign */
  ::-webkit-scrollbar {
    color: #e54c2a;
    scrollbar-width: none;
    height: 2px;
  }
  .card-des {
    min-width: 310px !important;
    min-height: 220px !important ;
    margin: auto 10px auto auto;
  }

  .btn {
    width: 105px !important;
    font-size: 11px !important;
  }
  .btn img {
    margin-left: -10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .col-12 {
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .col-12 {
    max-width: 33%;
  }
}
@media screen and (min-width: 1201px) {
  .col-12 {
    max-width: 25%;
  }
}
.gradient-inside {
  width: 100%;
  height: 100%;
  background: transparent;
  /* background: linear-gradient(312.02deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%); */
  border-radius: 28px;
  padding: 1px;
}
.gradient-inside img {
  /* background: linear-gradient(312.02deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%); */
  border-radius: 40px;
  padding: 7px;
  object-fit: fill;
  display: block;
  margin: auto;
  height: 100%;
  width: 100%;
}
.card-design {
  width: 100%;
  height: 280px;
  border-radius: 40px;
  padding: 3px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-top: 6px solid black;
  border-bottom: 7px solid black;
}

.product-name {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 14px;
  /* width: 270px; */
  text-align: center;
}
.product-name:hover a {
  color: #e54c2a;
}
.overlay {
  display: none;
  position: absolute;
  background-color: rgba(40, 40, 40, 0.5);
  height: 100%;
  width: 50%;
  top: 0;
}
.product:hover .overlay {
  display: inline;
}
.overlay-text {
  font-size: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  color: wheat;
  transform: translate(-50%, -50%);
}
/* .holder-div{
        margin: auto;
        height: 240px;
        width: 240px;
        padding: 10px;
        border: 1.5px solid black;
        box-shadow: 0 0 10px 0px gray;
        border-radius: 50%;
        position: relative;
        text-align: center;
        overflow: hidden;
    }
    .holder-div img{
        border-radius: 10%;
    } */

.discount {
  margin-left: -44px;
}
.text {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #231f20;
}
.btn {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border: none;
  letter-spacing: normal;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 6px;
  width: 125px;
  height: 28px;
  background: rgb(199, 9, 9);
}
</style>
