<template>
    <div class="m-0 mt-5 background_downloadApp row mb-4">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 parent">
                <div class="child">
                  <h1 class="header_text">DOWNLOAD<br> OUR APP</h1>
                  <div class="app_link">
                    <a href="https://play.google.com/store/apps/details?id=com.germanbutcher.showcase" target="__blank"><img src="/img/google-play-badge.svg" alt="Android App" class="image"></a>
                    <a href="https://apps.apple.com/us/app/german-butcher-bd/id1568524850" target="__blank"><img src="/img/App-Store-Badge.svg" alt="IOS App" class="image "></a> 
                  </div>
                </div>
            </div>
            <div class="w-100 d-md-none"></div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 parent d-none d-lg-flex justify-content-end">
                <div class="child_2">
                    <img src="/img/new_test/downloadApp/Artboard 1@3x 1.png" alt="phone in hand" class="image_hand"/>
                </div>
            </div>

</div>
    
</template>
<script>
  export default {
    name:'DownloadApp',

  }
</script>
<style>

.rel{
    position:absolute;
}

.child{
    position:absolute;
    top:38%;
    left:23%;
}
.child_2{
    position: absolute;
    bottom: 0;

}
.parent{
    position:relative;
}

.image_hand{
    height:auto;
    width: auto;
    max-width: 694px;
    max-height: 625px;
}
.app_link{
    display: flex;
    gap:10px;
}

.image{
    height: 66px;
    width: 220px;
    max-width: 100%;
    max-height: 100%;
}

@media  (max-width: 1024px) {
    .image_hand {
       max-height: 90%;
       max-width: 90%;
    }
}



@media (max-width:580px){
     .image_hand {
            max-height: 55%;
            max-width: 55%;
        }

    .child{
        position:absolute;
        top:10%;
        left:10%;
    }

    .image{
        height:60px;
        width: 160px;
    }
    
 }
 @media  (max-width: 767px){
    .app_link{
    display: block;
    }
    .image {
        margin-top: 5px;
        display: block;
    }
    .image_hand {
            height: 60%;
            width: 60%;
        }
    .background_downloadApp{
        min-height: 400px !important;
    }
}
@media (max-width:380px){ 
    .image_hand {
            height: 70%;
            width: 70%;
        }
}


@font-face {
    font-family: 'Castor Two W01 Regular';
    src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
}
.header_text{
    font-family: 'Castor Two W01 Regular';
    font-weight: 400;
    font-size:48px;
    color:white;
}

.background_downloadApp{
    min-height: 556px;
    position: relative;
    display: flex;
    overflow:visible;
    background-image: url('/img/new_test/downloadApp/red mobile 1.png');
    background-size:cover;
}




</style>