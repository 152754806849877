<template>
    <!-- <div class="slider-active owl-dot-style owl-carousel"> -->
    <carousel :items="1" :autoplay="true" :nav="false">
    <div  v-for="item in videos" :key="item.id" class="product-style-wrap">
        <div class="row">
            <div class="col-md-6 mt-10">
                <iframe width="100%" height="318" :src="makeLink(item.youtube_link)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-md-6">
                <div class="product-content">
                    <h4>
                        {{ item.title }}
                    </h4>
                    <P>{{ removeTags(item.description) }}</P>
                    <!-- <div class="default-btn-style">
                        <a href="#">read more</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    </carousel>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
    name: 'FeaturedProductSlider',
    components: { carousel },
    data () {
        return {
            youtubeBaseUrl: 'https://www.youtube.com/embed/'
        }
    },
    props: {
        videos: {required: true}
    },
    methods: {
        removeTags(item){
            return item.replace(/<[^>]*>?/gm, '');
        },
        makeLink(link){
            var splitString = link.split('watch?v=');
            return this.youtubeBaseUrl + splitString[1]
        }
    }
}
</script>