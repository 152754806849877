<template>
  <!-- <div class="hero-section">
  
  <div class="main-title">
    <h3>Authentic </h3>
    <h1> German Taste</h1>
    <h4>Since 1991</h4> 
    <p>Pioneer of sausages manufacturing in Bangladesh</p>
  </div>

</div> -->
<!-- Hero -->
<div class=" text-sm-justify text-xs-center bg-image rounded-3 hero-section">
  <div class=" container mask">
    <div class="d-flex h-100">
      <div class="main-title" style="margin-top: 100px;">
    <h3>Authentic </h3>
    <h1> German Taste</h1>
    <h4>Since 1991</h4> 
    <p>Pioneer of Sausages Manufacturing in Bangladesh</p>
  </div>
    </div>
  </div>
  <HomepageCategoty style="margin-top: 5px;" :color="color" />
</div>
<!-- Hero -->

</template>

<script>
import HomepageCategoty from './HomepageCategoty.vue';
export default {
  name: "NewSlider",
  components: {HomepageCategoty},
  data() {
    return {color: 'white'};
  },
};
</script>
<style scoped>
@font-face {
    font-family: 'Quicksand';
    src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
@font-face {
    font-family: 'CastorTwoW01-Regular';
    src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
} 

h1, h2 {
text-transform: uppercase;
font-family: 'CastorTwoW01-Regular';
font-style: normal;
font-weight: 400;
font-size: 90px;
line-height: 108px;
letter-spacing: 0.02em;

/* Golder Gradient */
background: linear-gradient(270deg, #D29835 0%, #F9ECC0 53.12%, #D29835 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
h3{
text-transform: uppercase;
font-family: 'CastorTwoW01-Regular';
font-style: normal;
font-weight: 400;
font-size: 50px;
line-height: 60px;
letter-spacing: 0.05em;

color: #FFFFFF
}
h4{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 35px;
letter-spacing: 0.1em;
text-transform: uppercase;

color: #FFFFFF;

}


.hero-section > .main-title {
  font-weight: 200;
}

.main-title{
    margin-left: 8.44rem;
}
.main-title p{
  font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 26px;
line-height: 32px;
/* identical to box height */
letter-spacing: -0.02em;

color: #FFFFFF;
}

.hero-section {
  min-width: 100%;
  background: no-repeat center center url('/img/ban.png');
  background-size: cover;
  height: 650px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  z-index: 1; position: relative;
}
@media only screen and (max-width: 769px) {

  .hero-section{
    height: 500px;
  }

  h1, h2 {

font-size: 30px;
line-height: normal;

}
h3{
  font-size: 20px;
  line-height: normal;
}
h4{
  font-size: 16px;
  line-height: normal;
}
.main-title{
    margin-left: 1rem;
    text-align: center;
}
.main-title p{
  font-size: 16px;
  padding: 5px 10px;
}
    
  }



</style>